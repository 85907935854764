import { Alert, Snackbar } from "@mui/material";
import { useEffect, useState, useCallback } from "react";
import { t } from "../i18n";
import { secondsToMinutesAndSeconds } from "../../helpers/timeFormat.helper";
import { useDispatch } from "react-redux";
import { logout } from "../redux/userSlice";
import { useNavigate } from "react-router-dom";
import { useSessionExpiration } from "./SessionExpiration.hook";

// Reduce to 30 seconds for testing
const WARNING_THRESHOLD = 30; // temporary: 30 seconds for testing

// Initial countdown value when warning starts (same as WARNING_THRESHOLD)
const EXPIRATION_COUNTDOWN = WARNING_THRESHOLD;

export const SessionExpirationChecker: React.FC = () => {
  // Add constant for minimum warning time
  const MIN_WARNING_TIME = 5; // seconds

  const [showWarning, setShowWarning] = useState(false);
  const [countdown, setCountdown] = useState(EXPIRATION_COUNTDOWN);
  const { isExpired, timeUntilExpiration, activateSessionExpiration } = useSessionExpiration();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = useCallback(() => {
    dispatch(logout());
    navigate("/login");
    setShowWarning(false);
  }, [dispatch, navigate]);

  // Activate session monitoring when component mounts
  useEffect(() => {
    activateSessionExpiration(true);
  }, []); // Run only once on mount

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (isExpired) {
      handleLogout();
    } else if (timeUntilExpiration <= WARNING_THRESHOLD && 
               timeUntilExpiration > MIN_WARNING_TIME && 
               !showWarning) {
      setShowWarning(true);
      setCountdown(timeUntilExpiration);
    }

    if (showWarning && !isExpired) {
      intervalId = setInterval(() => {
        setCountdown(prev => {
          if (prev <= 1) {
            handleLogout();
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isExpired, timeUntilExpiration, handleLogout, showWarning]);

  const handleCloseWarning = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setShowWarning(false);
  };

  return (
    <Snackbar 
      open={showWarning} 
      onClose={handleCloseWarning}
      anchorOrigin={{ horizontal: "center", vertical: "top" }}
      key="session_expiration_snackbar"
    >
      <Alert severity="warning" variant="filled" sx={{ width: "100%" }}>
        <div>
          {t("user_will_be_logged_out")} {secondsToMinutesAndSeconds(countdown)}
        </div>
      </Alert>
    </Snackbar>
  );
};