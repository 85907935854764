import jwtDecode, { InvalidTokenError, JwtPayload } from "jwt-decode";
import { TokenContent, UserRole } from "../types/token";
import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import { UserState } from "../types/user";
import { login } from "../../redux/userSlice";

interface ExtendedJwtPayload extends JwtPayload {
  user_code: string;
  user_name: string;
  role: string;
}

export const parseToken = (token: string | null): { isValid: boolean; tokenContent: TokenContent | undefined } => {
  if (!token) {
    return { isValid: false, tokenContent: undefined };
  }
  try {
    const decodedToken = jwtDecode<ExtendedJwtPayload>(token);
    const currentTime = Date.now();

    const tokenContent: TokenContent = {
      userCode: decodedToken.user_code,
      userName: decodedToken.user_name,
      role: decodedToken.role as UserRole,
      token: token,
      expire_at: decodedToken.exp ? decodedToken.exp * 1000 : null,
      tokenIssuedAt: decodedToken.iat ? decodedToken.iat * 1000 : null,
      tokenExpiresIn: decodedToken.exp ? decodedToken.exp * 1000 - currentTime : null,
    };

    const isValid = Boolean(tokenContent.expire_at && tokenContent.expire_at > currentTime);

    return { isValid, tokenContent };
  } catch (error) {
    if (error instanceof InvalidTokenError) {
      console.error("Invalid token", error);
    } else {
      console.error("Something went wrong during processing token", error);
    }
    return { isValid: false, tokenContent: undefined };
  }
};

export const dispatchLogin = (dispatch: Dispatch<AnyAction>, tokenContent: TokenContent): void => {
  const currentUser: UserState = {
    userCode: tokenContent.userCode,
    userName: tokenContent.userName,
    role: tokenContent.role,
    token: tokenContent.token,
    expire_at: tokenContent.expire_at,
    authenticated: true,
  };
  dispatch(login(currentUser));
};
