import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { t } from "../features/i18n";
import TSGLB360Viewer from "../features/ThreeSixtyViewer/TSGLB360Viewer";
import { useNavigate, useParams } from "react-router-dom";

import { DownloadAssetPart, GetAssetThumbnail, ListAssets } from "../apiRequest/assets";
import { VirtualRealityIcon } from "../components/icons/menu/VirtualRealityIcon";

export interface IGLBModels { }

const GLBModels: React.FC<IGLBModels> = (props) => {
  const [assets, setAssets] = useState<IAsset[]>([]);
  const [dataCheck, setDataCheck] = useState<boolean>(false);
  const [hasError, setHasError] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const apiCalled = useRef(false);
  const { groupName } = useParams();
  // Funkce pro aktualizaci šířky
  const updateWidth = () => {
    const newWidth = window.innerWidth;
    setWidth(newWidth);
  };

  // Použití useEffect pro naslouchání změnám velikosti okna
  useEffect(() => {
    window.addEventListener('resize', updateWidth);

    // Nastavení šířky při prvním renderu
    updateWidth();

    // Cleanup při unmountu
    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, []);

  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleResetPage = () => {
    fetchImages()
  };

  async function loadData(): Promise<void> {
    if (dataCheck !== true) {
      setDataCheck(true);
      fetchImages();
    }
  }

  useEffect(() => {
    if (!apiCalled.current) {
      loadData();
      apiCalled.current = true;
    }
  }, []);

  const fetchImages = async () => {
    try {
      // Step 1: Fetch the assets from the API
      const apiAssets = await ListAssets("model", groupName);

      console.log("apiAssets:");
      console.log(apiAssets);
      // Step 2: Add the models data to the image handler
      setAssets(apiAssets);
    } catch (error) {
      console.error("Something went wrong while fetching images", error);
    }
  };

  return (
    <div>
      <Box display="flex" alignItems="center" sx={{ mb: "45px " }}>
        <Typography variant="h4" sx={{ color: "#9b4c97", mb: 5 }}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item sx={{ mr: "36px" }}>
              <VirtualRealityIcon />
            </Grid>
            <Grid item>{t("models")}</Grid>
          </Grid>
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            position: "absolute",
            right: 16,
          }}
        >
          <Button
            variant="outlined"
            onClick={handleGoBack}
            sx={{
              width: 184,
              height: 40,
              color: "#72b855",
              textTransform: "none",
              fontWeight: "bold",
              borderColor: "#72b855",
              marginBottom: 2,
            }}
          >
            {t("back")}
          </Button>
        </Box>
      </Box>

      {dataCheck ? (
        <TSGLB360Viewer
          assets={assets}
          width={width}
        />
      ) : (
        <Typography sx={{}}>
          {t("not_find_any_models")}
        </Typography>
      )}
    </div>
  );
};

export default GLBModels;
