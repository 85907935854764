import api from "../api";

export async function CreateCurriculum(createCurriculumData: ICreateCurriculumData): Promise<void> {
    try {
        await api.post('/curriculums/create/', createCurriculumData);
        console.log("Curriculum created successfully");
    } catch (error) {
        console.error("Error occurred while creating Curriculum:", error);
        throw error;
    }
}

export async function UpdateCurriculum(updateCurriculumData: IUpdateCurriculumData): Promise<void> {
    try {
        await api.put('/curriculums/update/', updateCurriculumData);
        console.log("Curriculum updated successfully");
    } catch (error) {
        console.error("Error occurred while updating Curriculum:", error);
        throw error;
    }
}

export async function CreateCurriculumChapterData(createCurriculumChapterData: ICreateCurriculumChapterData): Promise<void> {
    try {
        await api.post('/curriculums/chapter/create/', createCurriculumChapterData);
        console.log("Curriculum chapter created successfully");
    } catch (error) {
        console.error("Error occurred while creating Curriculum chapter:", error);
        throw error;
    }
}

export async function ImportDefaultCurriculums(uuids: string[]): Promise<IImportDefaultCurriculumsResponse> {
    try {
        const { data } = await api.post<IImportDefaultCurriculumsResponse>('/curriculums/import_defaults/', { uuids });
        console.log("Default curriculums imported successfully:", data);
        return data;
    } catch (error) {
        console.error("Error occurred while importing default curriculums:", error);
        throw error;
    }
}

