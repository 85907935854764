import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Paper,
  Typography,
  Theme,
  Box,
  Grid,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useForm } from "react-hook-form";
import { t } from "../../features/i18n";
import { z } from 'zod';
import MessageComponent from "../MessageComponent";
import { CreateRemark, UpdateRemark } from "../../apiRequest/remark";

let color: string = "#44bcac";

const schema = z.object({
  title: z.string().min(1, t("name_min_one_symbol")),
  content: z.string().min(1),
});
type FormData = z.infer<typeof schema>;

const useStyles = makeStyles<Theme>((theme) => ({
  paper: {
    borderTop: `8px solid ${color}`,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: "40px",
  },
  tableRow: {
    "&:not(:last-child)": {
      borderBottom: "none",
    },
  },
}));

interface IRemarkModal {
  open: boolean;
  onClose: () => void;
  resetHandler: () => void;
  initialData?: { id: number, title: string; content: string };
};

const RemarkModal: React.FC<IRemarkModal> = ({ open, onClose, resetHandler, initialData }) => {
  const [id, setId] = useState<number>(initialData?.id || 0);
  const [title, setTitle] = useState<string>(initialData?.title || '');
  const [content, setContent] = useState<string>(initialData?.content || '');
  const [loading, setLoading] = useState<boolean>(false);
  const [successAlertOpen, setSuccessAlertOpen] = useState(false);
  const [errorAlertOpen, setErrorAlertOpen] = useState(false);

  const classes = useStyles();

  const { register, handleSubmit, formState: { errors }, reset } = useForm<FormData>({
    defaultValues: {
      title: initialData?.title || '',
      content: initialData?.content || ''
    },
    values: {
      content: initialData?.content || '',
      title: initialData?.title || '',
    }
  });

  useEffect(() => {
    if (initialData) {
      setId(initialData.id)
      setTitle(initialData.title);
      setContent(initialData.content);
    } else {
      setId(0)
      setTitle('');
      setContent('');
    }
  }, [initialData]);

  useEffect(() => {
    if (open) {
      setLoading(false);
      setErrorAlertOpen(false);
      setSuccessAlertOpen(false);
      if (initialData) {
        setId(initialData.id)
        setTitle(initialData.title);
        setContent(initialData.content);
      }
      reset();
    }
  }, [open]);

  const handleCancel = () => {
    onClose();
  };

  const onSubmit = async (data: FormData) => {
    try {
      if (!data) {
        throw "input data fail"
      }
      const remarkData: IRemarkData = {
        id: id,
        title: data.title,
        content: data.content,
        updated_at: ""
      };
      setLoading(true)
      if (initialData) {
        await UpdateRemark(remarkData);
      } else {
        await CreateRemark(remarkData);
      }
      setTimeout(() => {
        setSuccessAlertOpen(true);
        setTimeout(() => {
          resetHandler();
          onClose();
        }, 2000);
      }, 2000);
    } catch (error) {
      console.error('Chyba při odesílání:', error);
      setSuccessAlertOpen(true);
      setErrorAlertOpen(true);
      setLoading(false);
      setTimeout(() => {
        setSuccessAlertOpen(false);
        setErrorAlertOpen(false);
        onClose();
      }, 2000);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Paper className={classes.paper}>
        {successAlertOpen ? (
          <MessageComponent
            errorChange={errorAlertOpen}
            successMessage={initialData ? t("remark_modal_update_succesfull") : t("remark_modal_create_succesfull")}
            errorMessage={initialData ? t("remark_modal_update_error") : t("remark_modal_create_error")}
          />
        ) : (
          <Box>
            <Box
              sx={{
                maxHeight: "50px",
                alignText: "left",
                color: color,
              }}
            >
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item>
                  <Typography
                    sx={{
                      fontSize: "24px",
                    }}
                  >
                    {initialData ? t("remark_modal_update") : t("remark_modal_create")}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              {initialData ? (
                <>
                  <TextField sx={{ mb: 2, mt: 2 }}
                    {...register('title')}
                    required
                    label={t("remark_modal_title")}
                    defaultValue={initialData.title}
                    error={!!errors.title}
                    helperText={errors.title?.message}
                    fullWidth
                  />
                  <TextField
                    sx={{ mb: 2 }}
                    {...register('content')}
                    required
                    label={t("remark_modal_content")}
                    multiline
                    rows={3}
                    defaultValue={initialData.content}
                    error={!!errors.content}
                    helperText={errors.content?.message}
                    fullWidth
                  />
                </>
              ) : (
                <>
                  <TextField sx={{ mb: 2, mt: 2 }}
                    {...register('title')}
                    required
                    label={t("remark_modal_title")}
                    defaultValue={''}
                    error={!!errors.title}
                    helperText={errors.title?.message}
                    fullWidth
                  />
                  <TextField
                    sx={{ mb: 2 }}
                    {...register('content')}
                    required
                    label={t("remark_modal_content")}
                    multiline
                    rows={3}
                    defaultValue={''}
                    error={!!errors.content}
                    helperText={errors.content?.message}
                    fullWidth
                  />
                </>)}
              <Button
                variant="outlined"
                onClick={handleCancel}
                sx={{
                  width: 140,
                  height: 40,
                  color: color,
                  textTransform: "none",
                  fontWeight: "bold",
                  borderColor: color,
                }}
              >
                {t("cancel")}
              </Button>

              <Button
                variant="contained"
                type="submit"
                disabled={loading}
                sx={{
                  width: 140,
                  height: 40,
                  bgcolor: color,
                  "&:hover": {
                    backgroundColor: color,
                  },
                  fontWeight: "bold",
                  position: "absolute",
                  right: "40px",
                }}
              >
                {loading ? t("processing") : t("confirm")}
              </Button>
            </form>
          </Box>
        )}
      </Paper>
    </Modal>
  );
};

export default RemarkModal;
