import { NavBarItems } from "../../features/authentication/types/user";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { DashboardIcon } from "../icons/menu/DashboardIcon";
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import { CurriculumIcon } from "../icons/menu/CurriculumIcon";
import { t } from "../../features/i18n";
import { VirtualRealityIcon } from "../icons/menu/VirtualRealityIcon";

const adminItems: NavBarItems[] = [
  {
    index: true,
    path: "/overview",
    state: "overview",
    color: "#4785c3",
    selectedColor: "#E0F1FE",
    sidebarProps: {
      displayText: t("overview"),
      icon: <DashboardIcon />,
    },
  },
  {
    path: "/userManagement",
    state: "userManagement",
    color: "#faba42",
    selectedColor: "#FFF0D4",
    sidebarProps: {
      displayText: t("user_managment"),
      icon: <PeopleOutlineIcon />,
    },
  },
  {
    path: "/curriculum",
    state: "curriculum",
    color: "#f55ea4",
    selectedColor: "#FFDFEE",
    sidebarProps: {
      displayText: t("name_curriculum"),
      icon: <CurriculumIcon />,
    },
  },
  {
    path: "/virtualReality",
    state: "virtualReality",
    color: "#9b4c97",
    selectedColor: "#FDE7FD",
    sidebarProps: {
      displayText: t("vr"),
      icon: <VirtualRealityIcon />,
    },
  },
  {
    path: "/hint",
    state: "hint",
    color: "#a0a0a0",
    selectedColor: "#EFEFEF",
    sidebarProps: {
      displayText: t("name_hint"),
      icon: <HelpOutlineOutlinedIcon />,
    },
  },
];

export default adminItems;
