import React, { useEffect, useRef, useState } from "react";
import { GetAssetThumbnail } from "../../apiRequest/assets";
import { t } from "../i18n";

export interface IAssetGLBButton {
  asset: IAsset;
  borderColor:string;
  selectImage: (value: IAsset) => void;
}

const AssetGLBButton: React.FC<IAssetGLBButton> = ({
  asset,
  borderColor,
  selectImage,
}) => {
  const [imageSize, setImageSize] = useState({ width: "auto", height: "auto" });
  const [thumbnailUrl, setThumbnailUrl] = useState<string | undefined>(
    asset.urlThumbnail
  );
  const [hasError, setHasError] = useState(false);

  const apiCalled = useRef(false);

  const fetchAssetThumbnail = async () => {
    if (asset.code) {
      try {
        // Stáhne miniaturu pomocí API
        const imageBlob = await GetAssetThumbnail(asset.code);
        const objectURL = URL.createObjectURL(imageBlob);
        setThumbnailUrl(objectURL); // Nastaví URL miniatury do stavu
      } catch (error) {
        console.error("Something went wrong while fetching Asset Thumbnail", error);
      }
    }
  };

  useEffect(() => {
    // Načtení miniatury pouze jednou
    if (!apiCalled.current) {
      fetchAssetThumbnail();
      apiCalled.current = true;
    }
  }, [asset.code]);

  useEffect(() => {
    if (!thumbnailUrl) return;

    // Dynamické přizpůsobení velikosti obrázku
    const img = new Image();
    img.src = thumbnailUrl;
    img.onload = () => {
      const { naturalWidth, naturalHeight } = img;
      if (naturalWidth > naturalHeight) {
        setImageSize({ width: "100%", height: "auto" });
      } else {
        setImageSize({ width: "auto", height: "100%" });
      }
    };
  }, [thumbnailUrl]);

  const containerStyle: React.CSSProperties = {
    width: "150px",
    height: "150px",
    border: `5px solid ${borderColor}`,
    borderRadius: "10px",
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  };

  const imageStyle: React.CSSProperties = {
    ...imageSize,
    objectFit: "contain",
  };

  const nameStyle: React.CSSProperties = {
    position: "absolute",
    bottom: "0",
    left: "50%",
    transform: "translateX(-50%)",
    textAlign: "center",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    width: "100%",
    padding: "5px",
  };

  return (
    <div style={containerStyle} onClick={() => selectImage(asset)}>
    {thumbnailUrl && !hasError ? (
      <img
        src={thumbnailUrl}
        alt={`Thumbnail for ${asset.code}`}
        style={imageStyle}
        onError={() => setHasError(true)} // Pokud načítání selže, nastavíme chybu
      />
    ) : hasError ? (
      <span>{t("asset_loading_thumbnail_error")}</span> // Zobrazíme chybovou hlášku
    ) : (
      <span>{t("loading")}</span> // Indikátor načítání
    )}
    {asset.name && <div style={nameStyle}>{asset.name}</div>}
  </div>
  );
};

export default AssetGLBButton;
