export default function getFormattedDate(date: string): string {
  const dateObject = new Date(date);

  const day = dateObject.getDate();
  const month = dateObject.getMonth() + 1;
  const year = dateObject.getFullYear();

  const hour = dateObject.getHours();
  const min = dateObject.getMinutes().toString().padStart(2, '0');

  const formattedDate = `${day}.${month}.${year} ${hour}:${min}`
  return formattedDate;
}

export function getFormattedDayDate(date: string): string {
  const dateObject = new Date(date);

  const day = dateObject.getDate();
  const month = dateObject.getMonth() + 1;
  const year = dateObject.getFullYear();


  const formattedDate = `${day}.${month}.${year}`
  return formattedDate;
}

export function convertDocumentIcon(date: string): string {
  return date.replace(".", "");
}


export const formatDateTimeForInput = (dateTime: string | undefined): string => {
  if (!dateTime) return '';
  const dateTimeWithoutZ = dateTime.endsWith('Z') ? dateTime.slice(0, -1) : dateTime;

  return dateTimeWithoutZ.split('.')[0];
};

export const formatDateAndTime = (isoString: string): { date: string; time: string } => {
  const dateObj = new Date(isoString);

  // Formátování data na "dd.mm.yyyy"
  const date = dateObj.toLocaleDateString('cs-CZ', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
  });

  // Formátování času na "hh:mm"
  const time = dateObj.toLocaleTimeString('cs-CZ', {
      hour: '2-digit',
      minute: '2-digit',
  });

  return { date, time };
}