import { Box, Card, Typography } from "@mui/material";
import React from "react";
import { t } from "../features/i18n";
import { getGlobalConfigs } from "../configs/globalConfigs";
import { useSelector } from "react-redux";
import { RootState } from "../features/redux/store";
import useStyles from "../configs/styles";

let color: string = "#44bcac";

const AdminTools: React.FC = () => {
  const classes = useStyles();
  const globalConfigs = getGlobalConfigs();
  const branch = useSelector((state: RootState) => state.footer.branch);
  const commit = useSelector((state: RootState) => state.footer.commit);
  const version = useSelector((state: RootState) => state.footer.version);
  let appName = `${globalConfigs.appName} [${process.env.REACT_APP_ENV_NAME}]`;

  return (
    <Card
      className={classes.tableCard}
      elevation={3}
      style={{ borderTop: "8px solid" + color , marginBottom: "20px"}}
    >
      <Box>
        {t("web_app_version")}
        <Typography
          sx={{
            fontSize: "24px",
            fontWeight: "bold",
          }}
        >
          {process.env.REACT_APP_VERSION}
        </Typography>
        <br />
        {t("api_version")}
        <table>
          <tbody>
            <tr>
              <td>{t("version")}:</td>
              <td>{version}</td>
            </tr>
            <tr>
              <td>{t("commit")}:</td>
              <td>{commit}</td>
            </tr>
            <tr>
              <td>{t("branch")}:</td>
              <td>{branch}</td>
            </tr>
            <tr>
              <td> {t("current_env")}</td>
              <td>{appName}</td>
            </tr>
          </tbody>
        </table>
        <br />
      </Box>
    </Card>
  );
};

export default AdminTools;
