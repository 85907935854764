import api from "../api";


export async function CreateRemark(remarkData: IRemarkData): Promise<void> {
    try {
        await api.post('/remark/create/', remarkData, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        console.log("Remark created successfully");
    } catch (error) {
        console.error("Error occurred while creating remark:", error);
        throw error;
    }
}

export async function UpdateRemark(remarkData: IRemarkData): Promise<void> {
    try {
        await api.put('/remark/update/', remarkData, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        console.log("Remark updated successfully");
    } catch (error) {
        console.error("Error occurred while updating remark:", error);
        throw error;
    }
}

export async function ListRemarks(): Promise<IRemarkData[]> {
    try {
        const response = await api.get('/remark/list/');
        console.log("Remarks fetched successfully");
        return response.data;
    } catch (error) {
        console.error("Error occurred while listing remarks:", error);
        throw error;
    }
}

export async function DeleteRemark(remarkId: number): Promise<void> {
    try {
        await api.delete(`/remark/delete/`, {
            params: {
                remark_id: remarkId,
            },
            headers: {
                'Content-Type': 'application/json',
            },
        });
        console.log("Remark deleted successfully");
    } catch (error) {
        console.error("Error occurred while deleting remark:", error);
        throw error;
    }
}
