import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Paper,
  Typography,
  Theme,
  Box,
  Grid,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { t } from "../../features/i18n";
import api from "../../api";
import MessageComponent from "../MessageComponent";

let color: string = "#f55ea4";

const useStyles = makeStyles<Theme>((theme) => ({
  paper: {
    borderTop: `8px solid ${color}`,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: "40px",
  },
  tableRow: {
    "&:not(:last-child)": {
      borderBottom: "none",
    },
  },
}));

interface IAssignCurriculumModal {
  subjectID?: number;
  open: boolean;
  onClose: () => void;
  resetTable: () => void;
};


const AssignCurriculumModal: React.FC<IAssignCurriculumModal> = ({ open, onClose, resetTable, subjectID }) => {
  const [responseData, setResponseData] = useState<CurriculumsResponseBody[]>([]);
  const classes = useStyles();
  const [successAlertOpen, setSuccessAlertOpen] = useState(false);
  const [errorAlertOpen, setErrorAlertOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [isOptionSelected, setIsOptionSelected] = useState<boolean>(false);

  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    setSelectedOption(e.target.value);
    setIsOptionSelected(!!e.target.value);
  };

  useEffect(() => {
    if (open) {
      GetAllCurriculums()
      setSelectedOption('');
      setLoading(false);
      setSuccessAlertOpen(false);
      setErrorAlertOpen(false);
    }
  }, [open]);

  const handleCancel = () => {
    onClose();
  };

  const handleSubmit = async () => {
    setLoading(true);

    try {
      console.log("loading:", loading);
      console.log("handleSubmit:", selectedOption);
      if (subjectID) {
        await handleAssignCurriculum(parseInt(selectedOption), subjectID)
        setTimeout(() => {
          setSuccessAlertOpen(true);
          setTimeout(() => {
            resetTable();
            onClose();
          }, 2000);
        }, 2000);
      } else {
        throw "chyba pri prirazeni";
      }
    } catch (error) {
      console.error('Chyba při odesílání:', error);
      setErrorAlertOpen(true);
      setLoading(false);
      setTimeout(() => {
        setErrorAlertOpen(false);
        onClose();
      }, 2000);
    }
  };

  async function handleAssignCurriculum(curriculum_id: number, subjectIDs: number) {
    const objectAssignCurriculum: IAssignCurriculumData = {
      curriculum_id: curriculum_id,
      subject_id: subjectIDs,
    }

    console.log("handleAssignCurriculum:", objectAssignCurriculum);
    return api.post('/curriculum_subject/assign_curriculum_to_subject/', objectAssignCurriculum)
      .then((response) => {
        console.log("handleAssignCurriculum data:", response.data);
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }

  async function GetAllCurriculums() {
    try {
      api.get('/curriculums/list/')
        .then((response) => {
          const data = response.data;
          const curriculumTableRowsData: CurriculumsResponseBody[] = []
          if (data.length === 0) {
            curriculumTableRowsData.push({
              id: 0,
              created_at: "-.-.----",
              updated_at: "-.-.----",
              created_by: "",
              updated_by: "",
              name: "",
              content: t("curriculum_is_not_there"),
              icon: ""
            });
            console.log("data.id:", data.id);
            setResponseData(curriculumTableRowsData);

          } else {
            console.log("are not empty");
            response.data.map((data: CurriculumsResponseBody) => {
              curriculumTableRowsData.push(data);
            });
            console.log("curriculumTableRowsData:", curriculumTableRowsData);
            setResponseData(curriculumTableRowsData);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Paper className={classes.paper}>
        {successAlertOpen ? (
          <MessageComponent
            errorChange={errorAlertOpen}
            errorMessage={t("assign_error")}
            successMessage={t("assign_complete")}
          />
        ) : (
          <Box>
            <Box
              sx={{
                maxHeight: "50px",
                alignText: "left",
                color: color,
              }}
            >
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item>
                  <Typography
                    sx={{
                      fontSize: "24px",
                    }}
                  >
                    {t("select_curriculum_to_assign")}
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            <Select
              displayEmpty
              required
              value={selectedOption}
              onChange={handleSelectChange}
              fullWidth
              sx={{
                mt: 2,
                mb: 2,
              }}
            >
              <MenuItem value=""></MenuItem>
              {responseData?.map((classItem, index) => (
                <MenuItem key={index} value={classItem.id}>
                  {classItem.name} / {classItem.content}
                </MenuItem>
              ))}
            </Select>
            <Box sx={{ mt: "25px " }}>
              <Button
                variant="outlined"
                onClick={handleCancel}
                sx={{
                  width: 140,
                  height: 40,
                  color: color,
                  textTransform: "none",
                  fontWeight: "bold",
                  borderColor: color,
                }}
              >
                {t("cancel")}
              </Button>
              <Button
                variant="contained"
                onClick={handleSubmit}
                disabled={!isOptionSelected || loading}
                sx={{
                  width: 140,
                  height: 40,
                  bgcolor: color,
                  "&:hover": {
                    backgroundColor: color,
                  },
                  fontWeight: "bold",
                  position: "absolute",
                  right: "40px",
                }}
              >
                {loading ? t("assigning") : t("assign")}
              </Button>
            </Box>
          </Box>
        )}
      </Paper>
    </Modal>
  );
};

export default AssignCurriculumModal;
