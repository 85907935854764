import { useState, useEffect, useCallback } from 'react';
import jwt_decode from 'jwt-decode';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { dispatchLogin, parseToken } from "./helpers/token.helper";

// Time in seconds before token expiration when warning should appear
const EXPIRATION_COUNTDOWN = 180; // 3 minutes warning before logout

export const useSessionExpiration = () => {
  const [isExpired, setIsExpired] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [timeUntilExpiration, setTimeUntilExpiration] = useState(Infinity);
  const [intervalRef, setIntervalRef] = useState<NodeJS.Timer | null>(null);
  
  const dispatch = useDispatch();
  const storedToken = useSelector((state: RootState) => state.user.token);

  const checkTokenValidity = useCallback(() => {
    const currentToken = localStorage.getItem("token");
    const { isValid, tokenContent } = parseToken(currentToken);

    if (!currentToken || !isValid) {
      setIsExpired(true);
      return false;
    }

    if (tokenContent && tokenContent.expire_at) {
      const expirationThreshold = tokenContent.expire_at - EXPIRATION_COUNTDOWN * 1000;
      const isTokenExpired = Date.now() >= expirationThreshold;
      const newTimeUntilExpiration = Math.max(0, Math.floor((tokenContent.expire_at - Date.now()) / 1000));
      
      // Only update state if values have changed
      if (isExpired !== isTokenExpired) {
        setIsExpired(isTokenExpired);
      }
      if (timeUntilExpiration !== newTimeUntilExpiration) {
        setTimeUntilExpiration(newTimeUntilExpiration);
      }
      
      // Only dispatch login if token has changed
      if (currentToken !== storedToken && isValid) {
        dispatchLogin(dispatch, tokenContent);
      }
      
      return !isTokenExpired;
    }
    
    return false;
  }, [dispatch, storedToken, isExpired, timeUntilExpiration]);

  // Initial check and periodic background check
  useEffect(() => {
    const cleanup = () => {
      if (intervalRef) clearInterval(intervalRef);
      setIntervalRef(null);
    };

    if (isActive) {
      checkTokenValidity();
      const checkInterval = setInterval(checkTokenValidity, 30000);
      setIntervalRef(checkInterval);
    }

    return cleanup;
  }, [isActive, checkTokenValidity]);

  const activateSessionExpiration = useCallback((active: boolean, duration: number = 60) => {
    setIsActive(active);
  }, []); 

  return { isExpired, isActive, timeUntilExpiration, activateSessionExpiration };
};