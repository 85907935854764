import {
  ListItemButton,
  ListItemIcon,
  ListItem,
  ListItemText,
  Box,
} from "@mui/material";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import colorConfigs from "../../configs/colorConfigs";
import { NavBarItems } from "../../features/authentication/types/user";
import { RootState } from "../../features/redux/store";

type Props = {
  item: NavBarItems;
  open: boolean;
  pageOpen?: () => void;
};

const SidebarItem = ({ open, item,pageOpen }: Props) => {
  const { appState } = useSelector((state: RootState) => state.appState);

  return item.sidebarProps && item.path ? (
    <ListItem
      key={item.sidebarProps.displayText}
      disablePadding
      sx={{ display: "block" }}
    >
      <ListItemButton
        component={pageOpen ? 'button' : Link}
        to={pageOpen ?  undefined : item.path}
        onClick={pageOpen ? () => pageOpen() : undefined}
        sx={{
          
          "&: hover": {
            backgroundColor: item.selectedColor,
          },
          backgroundColor:
            appState === "/" + item.state
              ? item.selectedColor
              : "unset",
          paddingY: "0px",
          paddingX: "0px",
          minHeight: 45,
        }}
      >
        <div
          style={{
            width: "5px",
            height: "45px",
            backgroundColor: item.color,
            textAlign: "left",
            marginLeft: 0,
            blockSize: "fixed",
          }}
        ></div>
        <ListItemIcon
          sx={{
            color: colorConfigs.sidebar.color,
            // mr: open ? 3 : "auto",
            minWidth: 0,
            justifyContent: "center",
            px: 2,
          }}
        >
          <div style={{ color: item.color }}>
            {item.sidebarProps.icon}
          </div>
        </ListItemIcon>
        {open ? (
          <Box
            sx={{
              mr: 0,
              minWidth: 0,
              justifyContent: "center",
              marginRight: "20px",
            }}
          >
            <ListItemText primary={item.sidebarProps.displayText} />
          </Box>
        ) : (
          <div></div>
        )}
      </ListItemButton>
    </ListItem>
  ) : null;
};

export default SidebarItem;
