import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import { Box, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoginForm from "../components/LoginForm";
import { useSessionExpiration } from "../features/authentication/SessionExpiration.hook";
import { dispatchLogin, parseToken } from "../features/authentication/helpers/token.helper";
import { t } from "../features/i18n";
import { loginUser } from "../apiRequest/auth";

export interface ILoginProps { }

const Login: React.FC<ILoginProps> = () => {
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(false); // Stav pro sledování načítání
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { activateSessionExpiration } = useSessionExpiration();


  const handleLogin = async (userCode: string, password: string) => {
    setIsLoading(true);
    try {
      const response = await loginUser(userCode, password);
      const { token } = response;

      const { isValid, tokenContent } = parseToken(token);
      if (isValid && tokenContent) {
        dispatchLogin(dispatch, tokenContent);
        localStorage.setItem("token", token);
        activateSessionExpiration(true);
        setErrorMessage(null);
        navigate("/overview");
      } else {
        setErrorMessage(t("invalid_token"));
      }
    } catch (error: any) {
      setErrorMessage(handleError(error));
    } finally {
      setIsLoading(false);
    }
  };

  const handleError = (error: any): string => {
    if (error?.response?.data?.code) {
      switch (error.response.data.code) {
        case "INVALID_CREDENTIALS":
          return t("invalid_credentials");
        case "USER_NOT_FOUND":
          return t("user_not_found");
        case "ACCOUNT_LOCKED":
          return t("account_locked");
        default:
          return t("login_failed");
      }
    } else if (error?.response?.status) {
      // HTTP status kód jako fallback
      switch (error.response.status) {
        case 401:
          return t("unauthorized");
        case 500:
          return t("server_error");
        default:
          return t("unknown_error_occurred");
      }
    } else {
      // Fallback na obecnou chybu
      return t("unknown_error_occurred");
    }
  };

  const paperStyle = {
    paddingTop: 45,
    paddingBottom: 75,
    paddingLeft: 100,
    paddingRight: 100,
    width: 644,
    borderTop: "8px solid #f18733",
  };

  return (
    <Paper elevation={10} style={paperStyle}>
      <Box sx={{ zIndex: 1, position: "absolute", left: "50%", top: "20%", transform: "translateX(-50%)", color: "#FEE9D7" }}>
        <Person2OutlinedIcon sx={{ fontSize: 100 }} />
      </Box>
      <Box sx={{ zIndex: 2, position: "absolute", left: "50%", transform: "translateX(-50%)" }}>
        <Typography variant="h5" color="#f18733">
          {t("login_to_portal")}
        </Typography>
      </Box>
      <LoginForm handleLogin={handleLogin} errorMessage={errorMessage} isLoading={isLoading} />
    </Paper>
  );
};

export default Login;