import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import UpdatePanoramaModal from "../../components/modals/UpdatePanoramaModal";

type Props = {
  id: number;
  src: string;
  selectedNumber: number;
  parts?: number;
  name?: string;
  code?: string;
  selectImage: (value: number) => void;
  onEdit: () => void;
  onDelete: () => void;
};

const ImagePanoramaButton: React.FC<Props> = ({
  id,
  src,
  selectImage,
  parts,
  selectedNumber,
  name,
  code,
  onEdit,
  onDelete
}) => {
  const [imageSize, setImageSize] = useState({ width: "auto", height: "auto" });

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      const { naturalWidth, naturalHeight } = img;
      if (naturalWidth > naturalHeight) {
        setImageSize({ width: "100%", height: "auto" });
      } else {
        setImageSize({ width: "auto", height: "100%" });
      }
    };
  }, [src]);

  const containerStyle: React.CSSProperties = {
    width: "150px",
    height: "150px",
    border: `5px solid ${selectedNumber === id ? "red" : "black"}`,
    borderRadius: "10px",
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative", // Relativní pozice pro ikony a popis
  };

  const imageStyle: React.CSSProperties = {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  };

  const iconStyle: React.CSSProperties = {
    position: "absolute",
    top: "5px",
    right: "5px",
    display: "flex",
    gap: "5px",
  };

  const iconButtonStyle: React.CSSProperties = {
    cursor: "pointer",
    backgroundColor: "white",
    borderRadius: "50%",
    // padding: "5px",
  };

  const numberInfoStyle: React.CSSProperties = {
    position: "absolute",
    top: "5px",
    left: "5px",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    padding: "3px 8px",
    borderRadius: "5px",
    fontSize: "12px",
  };

  const nameStyle: React.CSSProperties = {
    position: "absolute",
    bottom: "0",
    left: "50%",
    transform: "translateX(-50%)",
    textAlign: "center",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    width: "100%",
    padding: "5px",
  };

  return (
    <div style={containerStyle}>
      <img
        src={src}
        alt={`Obrázek + ${id}`}
        style={imageStyle}
        onClick={() => selectImage(id)}
      />
      {code ? (<div style={iconStyle}>
        <div
          style={iconButtonStyle}
          onClick={() => onEdit()}
        >
          <EditIcon />
        </div>
        <div style={iconButtonStyle} onClick={() => onDelete()}>
          <DeleteIcon />
        </div>
      </div>) : (<></>)}


      {parts !== undefined && <div style={numberInfoStyle}>{parts}</div>}

      <div style={nameStyle}>{name ? name : ""}</div>


    </div>
  );
};

export default ImagePanoramaButton;
