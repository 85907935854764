import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserState } from "../authentication/types/user";

const initialState: UserState = {
  userCode: null,
  userName: null,
  role: null,
  token: null,
  authenticated: false,
  expire_at: null,
};

export const userSlice = createSlice({
  name: "userState",
  initialState,
  reducers: {
    login: (state, action: PayloadAction<UserState>) => {
      const { userCode, userName, role, token, authenticated } = action.payload;
      state.userCode = userCode;
      state.userName = userName;
      state.role = role;
      state.token = token;
      state.authenticated = authenticated;
    },
    logout: (state, action: PayloadAction<boolean | undefined>) => {
      state.authenticated = action.payload || false;
      state = initialState;
    },
  },
});

export const { login, logout } = userSlice.actions;

export default userSlice.reducer;
