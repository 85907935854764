import React from "react";
import { useRoutes } from "react-router-dom";
import MainLayout from "../../layouts/MainLayout";
import SimpleLayout from "../../layouts/SimpleLayout";
import Calendar from "../../pages/Calendar";
import Hint from "../../pages/Hint";
import Login from "../../pages/Login";

import Overview from "../../pages/Overview";
import Page404 from "../../pages/Page404";
import Subjects from "../../pages/Subjects";
import Tasks from "../../pages/Tasks";
import VirtualReality from "../../pages/VirtualReality";
// import Curriculum from "../../pages/Curriculum";
import LoginLayout from "../../layouts/LoginLayout";
import CalendarDetail from "../../pages/CalendarDetail";
import Classes from "../../pages/Classes";
import Models from "../../pages/Models";
import MyClass from "../../pages/MyClass";
import Panoramas from "../../pages/Panoramas";
import Recovery from "../../pages/Recovery";
import SubjectItemPage from "../../pages/SubjectItemPage";
import TaskDetails from "../../pages/TaskDetails";
import TaskDetailsStudent from "../../pages/TaskDetailsStudent";
import AuthGuard from "./AuthGuard";
import UsersManagement from "../../pages/UsersManagement";
import GLBModels from "../../pages/GLBModels";
import GLBModelsSelect from "../../pages/GLBModelsSelect";

const LazyCurriculum = React.lazy(() => import("../../pages/Curriculum"));
const LazyCurriculumDetail = React.lazy(() => import("../../pages/CurriculumDetail"));
export default function Router() {
  const routes = useRoutes([
    {
      path: "/",
      element: <MainLayout />,
      children: [
        { path: "/overview", element: <Overview />, index: true },
        { path: "/myClass/:classID", element: <MyClass /> },
        { path: "/myClass", element: <MyClass /> },
        { path: "/classes", element: <AuthGuard allowedRoles={["ADMIN", "SUPER_TEACHER", "TEACHER"]} />, children: [{ path: "/classes/", element: <Classes /> }] },
        {
          path: "/subjects/:subjectId/:subjectName",
          element: <SubjectItemPage />,
        },
        { path: "/subjects", element: <Subjects /> },
        { path: "/tasks", element: <Tasks /> },
        { path: "/taskDetail/:tasksId", element: <TaskDetails /> },
        { path: "/taskDetailStudent/:tasksId", element: <TaskDetailsStudent /> },
        { path: "/calendar", element: <Calendar /> },
        { path: "/calendar/:eventID", element: <CalendarDetail /> },
        { path: "/virtualReality/models", element: <Models /> },
        { path: "/virtualReality/glbselect", element: <GLBModelsSelect /> },
        { path: "/virtualReality/glbselect/models/:groupName", element: <GLBModels /> },
        { path: "/virtualReality/panoramas", element: <Panoramas /> },
        { path: "/virtualReality", element: <VirtualReality /> },

        {
          path: "/curriculum",
          element: <AuthGuard allowedRoles={["ADMIN", "SUPER_TEACHER", "TEACHER"]} />,
          children: [
            {
              path: "/curriculum/",
              element: (
                <React.Suspense fallback="Loading...">
                  <LazyCurriculum />
                </React.Suspense>
              ),
            },
          ],
        },
        {
          path: "/curriculumDetail/:curriculumID",
          element: <AuthGuard allowedRoles={["ADMIN", "SUPER_TEACHER", "TEACHER", "STUDENT"]} />,
          children: [
            {
              path: "/curriculumDetail/:curriculumID/",
              element: (
                <React.Suspense fallback="Loading...">
                  <LazyCurriculumDetail />
                </React.Suspense>
              ),
            },
          ],
        },
        { path: "/userManagement", element: <UsersManagement /> },
        { path: "/hint", element: <Hint /> },
      ],
    },
    {
      path: "/login",
      element: <LoginLayout />,
      children: [
        { path: "/login/", element: <Login />, index: true },
        { path: "/login/recovery", element: <Recovery /> },
      ],
    },
    {
      path: "*",
      element: <SimpleLayout />,
      children: [{ path: "*", element: <Page404 /> }],
    },
  ]);

  return routes;
}
