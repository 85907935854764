import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Card, Grid, Typography } from "@mui/material";
import { t } from "../features/i18n";
import TSGLB360Viewer from "../features/ThreeSixtyViewer/TSGLB360Viewer";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ListAssetGroups } from "../apiRequest/assets";

import useStyles from "../configs/styles";

export interface IGLBModelsSelect { }

const GLBModelsSelect: React.FC<IGLBModelsSelect> = (props) => {
  const [assetsGroups, setAssetsGroups] = useState<string[]>([]);
  const [dataCheck, setDataCheck] = useState<boolean>(false);
  const [hasError, setHasError] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const apiCalled = useRef(false);

  const classes = useStyles();
  const location = useLocation();

  // Funkce pro aktualizaci šířky
  const updateWidth = () => {
    const newWidth = window.innerWidth;
    setWidth(newWidth);
  };

  // Použití useEffect pro naslouchání změnám velikosti okna
  useEffect(() => {
    window.addEventListener('resize', updateWidth);

    // Nastavení šířky při prvním renderu
    updateWidth();

    // Cleanup při unmountu
    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, []);

  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleResetPage = () => {
    fetchGroups()
  };

  async function loadData(): Promise<void> {
    if (dataCheck !== true) {
      setDataCheck(false);// TODO set false after 
      fetchGroups();
    }
  }

  useEffect(() => {
    if (!apiCalled.current) {
      loadData();
      apiCalled.current = true;
    }
  }, []);

  const fetchGroups = async () => {
    try {
      // Step 1: Fetch the assets from the API
      const apiAssetsGroups = await ListAssetGroups();

      console.log("apiAssetsGroups:");
      console.log(apiAssetsGroups);
      if (apiAssetsGroups.length > 0) {
        setAssetsGroups(apiAssetsGroups);
        setDataCheck(true);
      } else {
        setDataCheck(false);
      }
      // Step 2: Add the models data to the image handler

    } catch (error) {
      console.error("Something went wrong while fetching images", error);
    }
  };

  return (
    <div>
      {dataCheck ? (
        <Grid sx={{ flexGrow: 1 }} container spacing={2}>
          <Grid item xs={12}>
            <Grid container justifyContent="left" spacing={4}>
              {assetsGroups.map((group, index) => (
                <Grid item key={index}>
                  <Card
                    className={classes.classCard}
                    elevation={3}
                    component={Link}
                    to={`${location.pathname}/models/${group}`}
                  >
                    <div style={{ width: '60%' }}>
                      <img
                        src="../3dglb.svg"
                        alt={`${group} icon`}
                        style={{
                          width: '100%',
                          height: 'auto',
                          display: 'block',
                          maxWidth: '100%',
                          objectFit: 'contain',
                        }}
                      />
                    </div>
                    <Typography variant="h6" align="center" sx={{ paddingTop: '8px' }}>
                      {group}
                    </Typography>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Typography sx={{}}>
          {t("not_find_any_models")}
        </Typography>
      )}
    </div>
  );
};

export default GLBModelsSelect;
