import React, { useState } from "react";
import { TextField, Button, Typography, Box } from "@mui/material";
import { t } from "../features/i18n";
import { Link } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";

interface LoginFormProps {
  handleLogin: (userCode: string, password: string) => void;
  errorMessage: string | null;
  isLoading: boolean;
}

const LoginForm: React.FC<LoginFormProps> = ({ errorMessage, handleLogin, isLoading }) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((prev) => !prev);
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const userCode = formData.get("userCode") as string;
    const password = formData.get("password") as string;
    handleLogin(userCode, password);
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        name="userCode"
        label={t("user_code")}
        placeholder={t("enter_user_code")}
        variant="filled"
        fullWidth
        required
        sx={{ mt: "80px" }}
      />
      <TextField
        name="password"
        label={t("password")}
        placeholder={t("enter_password")}
        type={showPassword ? "text" : "password"}
        variant="filled"
        fullWidth
        required
        sx={{ mt: "20px", mb: "30px" }}
        InputProps={{
          endAdornment: (
            <Box
              onClick={handleClickShowPassword}
              sx={{ cursor: "pointer", color: "#000" }}
            >
              {!showPassword ? <VisibilityOff /> : <Visibility />}
            </Box>
          ),
        }}
      />
      {errorMessage && (
        <Typography sx={{ color: "red" }}>
          {errorMessage}
        </Typography>
      )}
      <Typography>
        {t("message_forgot_password")}
        <Link to={"/login/recovery"}>{t("message_generate_new")}</Link>
      </Typography>
      <Button
        type="submit"
        color="primary"
        variant="contained"
        fullWidth
        disabled={isLoading}
        sx={{
          bgcolor: "#f18733",
          fontWeight: "bold",
          mt: "30px",
          "&:hover": {
            backgroundColor: "#f18733",
          },
        }}
      >
        {isLoading ? t("loading") : t("login")}
      </Button>
    </form>
  );
};

export default LoginForm;
