import React, { ReactElement, createContext } from "react";
import { useSessionExpiration } from "./SessionExpiration.hook";

type SessionExpirationContextType = {
  isExpired: boolean;
  isActive: boolean;
  timeUntilExpiration: number;
  activateSessionExpiration: (active: boolean, duration?: number) => void;
};

export const SessionExpirationContext = createContext<SessionExpirationContextType>({
  isExpired: false,
  isActive: false,
  timeUntilExpiration: Infinity,
  activateSessionExpiration: () => {
    console.error("Not implemented");
  },
});

export const SessionExpirationProvider: React.FC<{ children: ReactElement }> = ({ children }) => {
  const sessionExpiration = useSessionExpiration();

  return (
    <SessionExpirationContext.Provider value={sessionExpiration}>
      {children}
    </SessionExpirationContext.Provider>
  );
};
