import React, { useEffect, useRef, useState } from "react";
import Masonry from "react-masonry-css";
import { Box } from "@mui/material";
import GLTFViewer from "./GLTFViewer";
import AssetGLBButton from "./AssetGLBButton";
import { DownloadAssetPart } from "../../apiRequest/assets";

interface ITSGLB360Viewer {
  assets: IAsset[];
  width: number;
}

const TSGLB360Viewer: React.FC<ITSGLB360Viewer> = ({ assets, width }) => {
  const [selectedAsset, setSelectedAsset] = useState<IAsset>(assets[0]);
  const [selectedAssetUrl, setSelectedAssetUrl] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const [hasError, setHasError] = useState(false);
  const assetViewRef = useRef<any>(null);
  const apiCalled = useRef(false);

  const handleResetAsset = () => {
    if (assetViewRef.current) {
      assetViewRef.current.handleReset();
    }
  };

  const handleSelectModel = async (asset: IAsset) => {
    console.log("handleSelectModel");
    console.log(asset);
  
    // Uvolnit předchozí URL
    if (selectedAssetUrl) {
      URL.revokeObjectURL(selectedAssetUrl);
    }
  
    try {
      setSelectedAsset(asset);
      setLoading(true);
      const imageBlob = await DownloadAssetPart(asset.code, 0);
      const objectURL = URL.createObjectURL(imageBlob);
      setSelectedAssetUrl(objectURL);
      setLoading(false);
    } catch (error) {
      console.error("Chyba při načítání modelu:", error);
      setHasError(true);
    }
  };

  const loadData = async () => {
    handleResetAsset();
    await handleSelectModel(assets[0])
  };



  useEffect(() => {
    if (!apiCalled.current && assets.length > 0) {
      loadData();
      apiCalled.current = true;
    }
  }, [assets]);

  const breakpoints = {
    default: 8,
    2200: 7,
    1900: 6,
    1500: 5,
    1100: 4,
    900: 3,
    700: 2,
    550: 1,
  };

  return (
    <div>
      <Box
        sx={{
          m: "10px",

        }}
      >
        <GLTFViewer
          modelPath={selectedAssetUrl}
          loading={loading}
          onError={() => setHasError(true)}
          width={width - 262}
        />
      </Box>

      <Masonry
        breakpointCols={breakpoints}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {assets.map((asset, index) => (
          <AssetGLBButton
            key={asset.code || index} // Použití unikátního klíče, pokud existuje
            asset={{
              ...asset,
              urlThumbnail: asset.urlThumbnail || asset.url,
            }}
            borderColor={selectedAsset?.code === asset.code ? "red" : "black"}
            selectImage={handleSelectModel}
          />
        ))}
      </Masonry>

    </div>
  );
};

export default TSGLB360Viewer;
