import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Paper,
  Typography,
  Theme,
  Box,
  Grid,
  Alert,
  AlertTitle,
  TextField,
  IconButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { t } from "../../features/i18n";
import { z, ZodError } from 'zod';
import MyTextField from "./MyTextField";
import { RootState } from "../../features/redux/store";
import { useSelector } from "react-redux";
import { CreateCurriculum } from "../../apiRequest/curruculum";
import ImageIcon from '@mui/icons-material/Image';
import DeleteIcon from '@mui/icons-material/Delete';
import MessageComponent from "../MessageComponent";
import { CreateAsset, UpdateAsset } from "../../apiRequest/assets";
let color: string = "#9b4c97";

const MAX_FILE_SIZE = 30 * 1024 * 1024; // 30MB
const ACCEPTED_FILE_TYPES = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];

const schema = z.object({
  name: z.string().min(1, "Asset name is required"),
  description: z.string().min(1, "Description is required")
});

type FormData = z.infer<typeof schema>;

const useStyles = makeStyles<Theme>((theme) => ({
  paper: {
    borderTop: `8px solid ${color}`,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: "40px",
  },
  tableRow: {
    "&:not(:last-child)": {
      borderBottom: "none",
    },
  },
}));

interface IUpdatePanoramaModal {
  open: boolean;
  onClose: () => void;
  resetTable: () => void;
  panoramaData: IUpdateAssetData;
};

const UpdatePanoramaModal: React.FC<IUpdatePanoramaModal> = ({ open, onClose, resetTable,panoramaData }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [successAlertOpen, setSuccessAlertOpen] = useState(false);
  const [errorAlertOpen, setErrorAlertOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]); 
  const classes = useStyles();
  const { register, handleSubmit, formState: { errors }, setValue, trigger } = useForm<FormData>({
    resolver: zodResolver(schema),
  });
  const [error, setError] = useState<string | null>(null);


   const onSubmit: SubmitHandler<FormData> = async (data) => {

    try {
      const validatedData = schema.parse(data);
      const assetData: IUpdateAssetData = {
        code: panoramaData.code,
        name: validatedData.name,
        description: validatedData.description,
      };
      console.log(`validation done ${data}`)
      setLoading(true)
      await UpdateAsset(assetData);
      setTimeout(() => {

        setSuccessAlertOpen(true);
        setTimeout(() => {
          resetTable();
          onClose();
        }, 2000);
      }, 2000);

    } catch (error) {
      console.error('Chyba při odesílání:', error);
      setError('Failed to create asset. Please try again.');
      setErrorAlertOpen(true);
      setLoading(false);
      setTimeout(() => {
        setErrorAlertOpen(false);
        onClose();
      }, 2000);
    }
  };

  useEffect(() => {
    if (open) {
      setLoading(false);
      setSuccessAlertOpen(false);
      setErrorAlertOpen(false);
      setError(null);
    }
  }, [open]);

  const handleCancel = () => {
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Paper className={classes.paper}>
        {
          successAlertOpen ? (
            <MessageComponent
              errorChange={errorAlertOpen}
              errorMessage={t("panoramas_modal_asset_update_error")}
              successMessage={t("panoramas_modal_asset_update_successfully")}
            />
          ) : (
            <Box>
              <Box
                sx={{
                  maxHeight: "50px",
                  alignText: "left",
                  color: color,
                }}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography
                      sx={{
                        fontSize: "24px",
                      }}
                    >
                      {t("panoramas_modal_update")}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>

              <form onSubmit={handleSubmit(onSubmit)}>
              <TextField
                sx={{ mb: 2, mt: 2 }}
                label={t("panoramas_modal_name")}
                defaultValue={panoramaData?.name}
                fullWidth
                {...register('name')}
                error={!!errors.name}
                helperText={errors.name?.message}
              />
                <TextField
                sx={{ mb: 2 }}
                label={t("panoramas_modal_description")}
                defaultValue={panoramaData?.description}
                fullWidth
                multiline
                rows={3}
                {...register('description')}
                error={!!errors.description}
                helperText={errors.description?.message}
              />

              {error && <Alert severity="error">{error}</Alert>}
              <Button
                    variant="outlined"
                    onClick={handleCancel}
                    sx={{
                      width: 140,
                      height: 40,
                      color: color,
                      textTransform: "none",
                      fontWeight: "bold",
                      borderColor: color,
                    }}
                  >
                    {t("cancel")}
                  </Button>

                  <Button
                    variant="contained"
                    type="submit"
                    disabled={loading}
                    sx={{
                      width: 140,
                      height: 40,
                      bgcolor: color,
                      "&:hover": {
                        backgroundColor: color,
                      },
                      fontWeight: "bold",
                      position: "absolute",
                      right: "40px",
                    }}
                  >
                    {loading ? t("assigning") : t("confirm")}

                  </Button >
              </form>
            </Box >
          )}
      </Paper >
    </Modal >
  );
};

export default UpdatePanoramaModal;


