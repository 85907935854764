import {
  Box,
  CSSObject,
  Icon,
  IconButton,
  List,
  styled,
  Theme,
  Toolbar,
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import React, { useState } from "react";
import sizeConfigs from "../../configs/sizeConfigs";
import ExpandCircleDownOutlinedIcon from "@mui/icons-material/ExpandCircleDownOutlined";
import SidebarItem from "./SidebarItem";
import studentItems from "./studentItems";
import { useSelector } from "react-redux";
import teacherItems from "./teacherItems";
import { RootState } from "../../features/redux/store";
import { NavBarItems } from "../../features/authentication/types/user";
import adminItems from "./adminItems";

const drawerWidth = sizeConfigs.sidebar.width;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  // transition: theme.transitions.create("width", {
  //   easing: theme.transitions.easing.sharp,
  //   duration: theme.transitions.duration.enteringScreen,
  // }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  // transition: theme.transitions.create("width", {
  //   easing: theme.transitions.easing.sharp,
  //   duration: theme.transitions.duration.leavingScreen,
  // }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export interface ISidebarProps {}

const Sidebar: React.FC<ISidebarProps> = () => {
  const role = useSelector((state: RootState) => state.user.role);
  const [open, setOpen] = useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleClick = () => {
    window.open("https://www.reklamaceskoly.cz/", "_blank", "noopener,noreferrer");
  };

  function getItems(): NavBarItems[] {
    let Items;
    switch (role) {
      case "STUDENT":
        Items = studentItems;
        break;

      case "TEACHER":
        Items = teacherItems;
        break;

      case "ADMIN":
        Items = adminItems;
        break;

      case "SUPER_TEACHER":
        Items = adminItems;
        break;

      default:
        Items = studentItems;
        break;
    }

    return Items;
  }

  return (
    <Drawer variant="permanent" open={open}>
      <Toolbar />

      <Box sx={{ overflow: "auto", position: "relative", }}>
        <List disablePadding>
          {getItems()
            .slice(0, getItems().length - 1)
            .map((route, index) => (
              <SidebarItem open={open} item={route} key={index} />
            ))}
        </List>
      </Box>
      {open ? (
        <IconButton onClick={handleDrawerClose}>
          <Icon style={{ transform: "rotate(90deg)" }}>
            <ExpandCircleDownOutlinedIcon />
          </Icon>
        </IconButton>
      ) : (
        <IconButton onClick={handleDrawerOpen}>
          <Icon style={{ transform: "rotate(275deg)" }}>
            <ExpandCircleDownOutlinedIcon />
          </Icon>
        </IconButton>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-end",
          position: "absolute",
          bottom: "52px",
        }}
      >
        <List sx={{ width: "250px", }} disablePadding>
          {getItems()
            .slice(getItems().length - 1, getItems().length)
            .map((route, index) => (
              <SidebarItem open={open} item={route} key={index} pageOpen={handleClick}/>
            ))}
        </List>
      </Box>
    </Drawer>
  );
};

export default Sidebar;
