import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { t } from "../../features/i18n";
import PannellumViewer from "./PannellumViewer";
import ImagePanoramaButton from "./ImagePanoramaButton";
import Masonry from "react-masonry-css";

interface TSPanoramaViewerProps {
  images: GroupedImages;
  onEdit: (image: IImageParameters[]) => void;
  onDelete: (image: IImageParameters[]) => void;
  width?: number;
}

const PanoramaViewer: React.FC<TSPanoramaViewerProps> = ({
  images,
  onEdit,
  onDelete,
  width,
}) => {
  const [currentImageUrl, setCurrentImageUrl] = useState("");
  const [selectedImageGroup, setSelectedImageGroup] = useState<number>(0);
  const [selectedImageId, setSelectedImageId] = useState<number>(0);
  const imageViewRef = useRef<any>(null);
  const apiCalled = useRef(false);

  async function loadData(): Promise<void> {
    console.log("PanoramaViewer useEffect");
    handleResetImage();
    fetchData();
    await renderImages();
  }
  useEffect(() => {
    if (!apiCalled.current) {
      loadData();
      apiCalled.current = true;
    }
  }, []);

  useEffect(() => {
    loadData();
  }, [selectedImageGroup, selectedImageId]);
  const breakpoints = {
    default: 8,
    1700: 7,
    1500: 6,
    1300: 5,
    1100: 4,
    900: 3,
    700: 2,
    550: 1,
  };
  const imagesRef = useRef<HTMLImageElement[]>([]);

  let imageData: any[] = [];
  let a: number = 0;

  const fetchData = () => {
    console.log(`fetchData inside images :`);
    imageData = [];
    console.log(selectedImageGroup);
    try {
      images[selectedImageGroup].map((image) => {
        imageData.push(image.path);
      });
      console.log(imageData);
    } catch (error) {
      console.log(error);
    }
  };

  const renderImages = async (): Promise<void> => {
    await loadImages();
    const currentImage = imagesRef.current[selectedImageId];
    console.log("imagesRef");
    console.log(imagesRef);
    try {
      setCurrentImageUrl(currentImage.src);
    } catch (error) {
      console.log(error);
    }
  };

  const loadImage = async (path: string): Promise<HTMLImageElement> => {
    const amount = images[selectedImageGroup].map((image) => image.amount);
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        resolve(img);
        a++;
      };
      img.onerror = reject;
      img.src = path;
    });
  };

  const loadImages = async (): Promise<void> => {
    a = 0;
    console.log("imageData");
    console.log(imageData);
    const images = await Promise.all(imageData.map((path) => loadImage(path)));
    console.log("images");
    console.log(images);
    images.sort((a, b) => {
      const aIndex = imageData.indexOf(a.src);
      const bIndex = imageData.indexOf(b.src);
      return aIndex - bIndex;
    });
    imagesRef.current = images;
  };

  const handlePreviousPhoto = () => {
    if (1 <= selectedImageId) {
      setSelectedImageId(selectedImageId - 1);
    } else {
      setSelectedImageId(images[selectedImageGroup].length - 1);
    }
  };

  const handleNextPhoto = () => {
    if (images[selectedImageGroup].length - 1 <= selectedImageId) {
      setSelectedImageId(0);
    } else {
      setSelectedImageId(selectedImageId + 1);
    }
  };

  const handleSelectModel = async (imgNum: number) => {
    setSelectedImageGroup(imgNum);
    setSelectedImageId(0);
  };

  const handleResetImage = () => {
    if (imageViewRef.current) {
      imageViewRef.current.handleReset();
    }
  };

  const getImageDescription = (): string => {
    
    try {
      let firstImage: { description?: string } | undefined;
      const imageGroup = images[selectedImageGroup];
      firstImage = imageGroup?.[0];
      return firstImage.description || '';
    } catch (error) {
      return  '';
    }
 };

  const getSelectedStatus = (): string => {
    try {
      return (selectedImageId + 1).toString() + "/" + images[selectedImageGroup].length.toString();
    } catch (error) {
      return "undefined"
    }
  };

  return (
    <div>
      <Box
        sx={{
          m: "10px",
          width: `${width}-240px`,
        }}
      >
        <PannellumViewer imageUrl={currentImageUrl} width={width} />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "10px",
          }}
        >
          <Button
            variant="text"
            style={{
              width: 140,
              height: 40,
              color: "black",
              backgroundColor: "white",
              fontWeight: "bold",
            }}
            startIcon={<KeyboardArrowLeftIcon />}
            onClick={() => handlePreviousPhoto()}
          >
            {t("previous_photo")}
          </Button>

          <Typography
            variant="h6"
            style={{
              backgroundColor: "#f0f0f0",
              padding: "10px 20px",
              borderRadius: "4px",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            {getSelectedStatus()}
          </Typography>

          <Button
            variant="text"
            style={{
              width: 140,
              height: 40,
              color: "black",
              backgroundColor: "white",
              fontWeight: "bold",
            }}
            startIcon={<KeyboardArrowRightIcon />}
            onClick={() => handleNextPhoto()}
          >
            {t("next_photo")}
          </Button>
        </div>

        <Typography variant="h6" sx={{ mt: 2, mb: 3 }}>
          {t("panoramas_modal_description") + ": " + getImageDescription()}
        </Typography>

        <Typography variant="h5" sx={{ mt: 2, mb: 2 }}>
          {t("panoramas_viewer_select_panorama")}
        </Typography>
        <Masonry
          breakpointCols={breakpoints}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {Object.keys(images).map((groupId) => {
            const imageGroup = images[parseInt(groupId)];
            const firstImage = imageGroup?.[0];

            if (!firstImage) return null;
            return (
              <ImagePanoramaButton
                key={groupId}
                id={firstImage.id}
                selectImage={handleSelectModel}
                selectedNumber={selectedImageGroup}
                parts={imageGroup.length}
                name={firstImage.name || firstImage.description}
                src={firstImage.path}
                code={firstImage.code}
                onEdit={() => onEdit(imageGroup)}
                onDelete={() => onDelete(imageGroup)}
              />
            );
          })}
        </Masonry>
      </Box>
    </div>
  );
};

export default PanoramaViewer;
