import api from "../api";

export async function CreateAsset(assetData: ICreateAssetData): Promise<void> {
    const formData = new FormData();

    assetData.files.forEach((file) => {
        formData.append('files', file);
    });

    formData.append('name', assetData.name);
    formData.append('group', assetData.group);
    formData.append('description', assetData.description);
    formData.append('asset_type', assetData.asset_type);

    try {
        await api.post('/assets/create/', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        console.log("Asset created successfully");
    } catch (error) {
        console.error("Error occurred while creating asset:", error);
        throw error;
    }
}

export async function GetAsset(asset_code: string): Promise<string> {
    try {
        const response = await api.get('/assets/', {
            params: {
                asset_code: asset_code,
            },
        });

        console.log("Asset retrieved successfully");
        return response.data;
    } catch (error) {
        console.error("Error occurred while retrieving asset:", error);
        throw error;
    }
}

export async function ListAssets(asset_type?: AssetType, group?: string): Promise<IAsset[]> {
    try {
        const response = await api.get('/assets/list/', {
            params: {
                asset_type: asset_type,
                group: group,
            },
        });

        console.log("Assets retrieved successfully");
        return response.data;
    } catch (error) {
        console.error("Error occurred while listing assets:", error);
        throw error;
    }
}

export async function ListAssetGroups(): Promise<string[]> {
    try {
        const response = await api.get('/assets/groups/list/');

        console.log("Asset groups retrieved successfully");
        return response.data;
    } catch (error) {
        console.error("Error occurred while listing asset groups:", error);
        throw error;
    }
}

export async function DeleteAsset(asset_code: string): Promise<void> {
    try {
        await api.delete('/assets/delete/', {
            params: {
                asset_code: asset_code,
            },
        });

        console.log("Asset deleted successfully");
    } catch (error) {
        console.error("Error occurred while deleting asset:", error);
        throw error;
    }
}

export async function UpdateAsset(assetData: IUpdateAssetData): Promise<void> {
    try {
        await api.put('/assets/update/', assetData, {
            headers: {
                'Content-Type': 'application/json',
            },
        });

        console.log("Asset updated successfully");
    } catch (error) {
        console.error("Error occurred while updating asset:", error);
        throw error;
    }
}

export async function DownloadAssetPart(asset_code: string, part: number): Promise<Blob> {
    try {
        const response = await api.get('/assets/download/', {
            params: {
                asset_code: asset_code,  
                part: part,            
            },
            responseType: 'blob',
        });

        console.log("Asset part downloaded successfully");
        return response.data; 
    } catch (error) {
        console.error("Error occurred while downloading asset part:", error);
        throw error;
    }
}

export async function GetAssetThumbnail(asset_code: string): Promise<Blob> {
    try {
        const response = await api.get('/assets/thumbnail/', {
            params: {
                asset_code: asset_code,
            },
            responseType: 'blob',
        });

        console.log("Asset thumbnail retrieved successfully");
        return response.data;
    } catch (error) {
        console.error("Error occurred while retrieving asset thumbnail:", error);
        throw error;
    }
}

