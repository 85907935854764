import React from 'react';
import { Pannellum } from 'pannellum-react';
import './styles/pannellum.css'; 

interface PannellumViewerProps {
  imageUrl: string;
  pitch?: number;
  yaw?: number;
  hfov?: number;
  width?:  number;
}

const PannellumViewer: React.FC<PannellumViewerProps> = ({ imageUrl, pitch = 10, yaw = 180, hfov = 110, width }) => {
  return (
    <div style={{ width: `${width}px`, height: '500px', paddingRight: '500px', boxSizing: 'border-box' }}>
      <Pannellum
        width="100%"
        height="100%"
        image={imageUrl}
        pitch={pitch}
        yaw={yaw}
        hfov={hfov}
        autoLoad
        showControls
      />
    </div>
  );
};

export default PannellumViewer;
