import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Paper,
  Typography,
  Theme,
  Box,
  Grid,
  Alert,
  TextField,
  IconButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useForm, SubmitHandler } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { t } from "../../features/i18n";
import { z } from "zod";

import ImageIcon from "@mui/icons-material/Image";
import DeleteIcon from "@mui/icons-material/Delete";
import MessageComponent from "../MessageComponent";
import { CreateAsset } from "../../apiRequest/assets";
let color: string = "#9b4c97";

const MAX_FILE_SIZE = 30 * 1024 * 1024; // 30MB
const ACCEPTED_FILE_TYPES = [
  "image/jpeg",
  "image/png",
  "image/gif",
  "image/webp",
];

const schema = z.object({
  name: z.string().min(1, "Asset name is required"),
  description: z.string().min(1, "Description is required"),
  files: z
    .array(z.instanceof(File))
    .min(1, "You must upload at least one file")
    .refine(
      (files) => {
        return files.every(
          (file) =>
            file.size <= MAX_FILE_SIZE &&
            ACCEPTED_FILE_TYPES.includes(file.type)
        );
      },
      {
        message:
          "Each file must be an image (jpeg, png, gif) and no larger than 30MB.",
      }
    ),
});
type FormData = z.infer<typeof schema>;

const useStyles = makeStyles<Theme>((theme) => ({
  paper: {
    borderTop: `8px solid ${color}`,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: "40px",
  },
  tableRow: {
    "&:not(:last-child)": {
      borderBottom: "none",
    },
  },
}));

interface ICreatePanoramaModal {
  open: boolean;
  onClose: () => void;
  resetTable: () => void;
}

const CreatePanoramaModal: React.FC<ICreatePanoramaModal> = ({
  open,
  onClose,
  resetTable,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [successAlertOpen, setSuccessAlertOpen] = useState(false);
  const [errorAlertOpen, setErrorAlertOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const classes = useStyles();
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
  } = useForm<FormData>({
    resolver: zodResolver(schema),
  });
  const [error, setError] = useState<string | null>(null);

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    try {
      const validatedData = schema.parse(data);
      const assetData: ICreateAssetData = {
        files: validatedData.files,
        name: validatedData.name,
        description: validatedData.description,
        asset_type: "panorama",
        group:"panorama",
      };
      console.log(`validation done ${data}`);
      setLoading(true);
      await CreateAsset(assetData);
      setTimeout(() => {
        setSuccessAlertOpen(true);
        setTimeout(() => {
          resetTable();
          onClose();
        }, 2000);
      }, 2000);
    } catch (error) {
      console.error("Chyba při odesílání:", error);
      setError("Failed to create asset. Please try again.");
      setErrorAlertOpen(true);
      setLoading(false);
      setTimeout(() => {
        setErrorAlertOpen(false);
        onClose();
      }, 2000);
    }
  };

  useEffect(() => {
    if (open) {
      setLoading(false);
      setSuccessAlertOpen(false);
      setErrorAlertOpen(false);
      setError(null);
      setSelectedFiles([]);
      reset({
        name: "",
        description: "",
        files: [],
      });
    }
  }, [open]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files ? Array.from(event.target.files) : [];
    setSelectedFiles(files);
    setValue("files", files);
    trigger("files");
  };

  const handleRemoveFile = (indexToRemove: number) => {
    const updatedFiles = selectedFiles.filter(
      (_, index) => index !== indexToRemove
    );
    setSelectedFiles(updatedFiles);
    setValue("files", updatedFiles);
    trigger("files");
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Paper className={classes.paper}>
        {successAlertOpen ? (
          <MessageComponent
            errorChange={errorAlertOpen}
            errorMessage={t("panoramas_modal_asset_created_error")}
            successMessage={t("panoramas_modal_asset_created_successfully")}
          />
        ) : (
          <Box>
            <Box
              sx={{
                maxHeight: "50px",
                alignText: "left",
                color: color,
              }}
            >
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item>
                  <Typography
                    sx={{
                      fontSize: "24px",
                    }}
                  >
                    {t("panoramas_modal_create")}
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            <form onSubmit={handleSubmit(onSubmit)}>
              <TextField
                sx={{ mb: 2, mt: 2 }}
                label={t("panoramas_modal_name")}
                fullWidth
                {...register("name")}
                error={!!errors.name}
                helperText={errors.name?.message}
              />
              <TextField
                sx={{ mb: 2 }}
                label={t("panoramas_modal_description")}
                fullWidth
                multiline
                rows={3}
                {...register("description")}
                error={!!errors.description}
                helperText={errors.description?.message}
              />
              <Button
                variant="outlined"
                component="label"
                fullWidth
                sx={{
                  mb: 2,
                  color: color,
                  textTransform: "none",
                  fontWeight: "bold",
                  borderColor: color,
                }}
              >
                {t("panoramas_modal_upload_files")}
                <input
                  type="file"
                  hidden
                  multiple
                  accept=".jpg,.jpeg,.png,.gif,.webp"
                  onChange={handleFileChange}
                />
              </Button>
              {selectedFiles.length > 0 && (
                <Box sx={{ mb: 2 }}>
                  <Typography variant="body1">{t("panoramas_modal_selected_files")}</Typography>
                  <ul>
                    {selectedFiles.map((file, index) => (
                      <li
                        key={index}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          marginBottom: "10px",
                        }}
                      >
                        {/* Image Icon */}
                        <Box display="flex" alignItems="center">
                          <ImageIcon sx={{ mr: 1 }} />

                          <Typography variant="body2" sx={{ mr: 1 }}>
                            {file.name}
                          </Typography>
                        </Box>

                        <Typography variant="body2" sx={{ mr: 1 }}>
                          {(file.size / 1024 / 1024).toFixed(2)} MB
                        </Typography>

                        <IconButton
                          onClick={() => handleRemoveFile(index)}
                          color="error"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </li>
                    ))}
                  </ul>
                </Box>
              )}
              {errors.files && (
                <Typography color="error">{errors.files?.message}</Typography>
              )}
              {error && <Alert severity="error">{error}</Alert>}
              <Button
                variant="outlined"
                onClick={handleCancel}
                sx={{
                  width: 140,
                  height: 40,
                  color: color,
                  textTransform: "none",
                  fontWeight: "bold",
                  borderColor: color,
                }}
              >
                {t("cancel")}
              </Button>

              <Button
                variant="contained"
                type="submit"
                disabled={loading}
                sx={{
                  width: 140,
                  height: 40,
                  bgcolor: color,
                  "&:hover": {
                    backgroundColor: color,
                  },
                  fontWeight: "bold",
                  position: "absolute",
                  right: "40px",
                }}
              >
                {loading ? t("assigning") : t("confirm")}
              </Button>
            </form>
          </Box>
        )}
      </Paper>
    </Modal>
  );
};

export default CreatePanoramaModal;
