import api from "../api";

export async function loginUser(usercode: string, password: string): Promise<{
    token: string;
    expire_at: number;
    role: string;
    user_code: string;
    user_name: string;
}> {
    try {
        const response = await api.post('/auth/login/', {
            usercode: usercode,
            password: password,
        });

        console.log("User logged in successfully");
        return response.data;
    } catch (error) {
        console.error("Error occurred during login:", error);
        throw error;
    }
}

export async function changePassword(userCode: string, oldPassword: string, newPassword: string): Promise<{
    token: string;
    expire_at: number;
    role: string;
    user_code: string;
    user_name: string;
}> {
    try {
        const response = await api.post('/auth/change_password/', {
            user_code: userCode,
            old_password: oldPassword,
            new_password: newPassword,
        });

        console.log("Password changed successfully");
        return response.data;
    } catch (error) {
        console.error("Error occurred while changing password:", error);
        throw error;
    }
}


export async function loginUserFake(usercode: string, password: string): Promise<never> {
    const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
    await delay(500);
    const errors = [
        {
            response: {
                data: { code: "INVALID_CREDENTIALS", message: "Invalid credentials provided" },
                status: 401,
            },
        },
        {
            response: {
                data: { code: "USER_NOT_FOUND", message: "User not found" },
                status: 404,
            },
        },
        {
            response: {
                data: { code: "ACCOUNT_LOCKED", message: "Account is locked" },
                status: 403,
            },
        },
        {
            response: {
                data: { code: "SERVER_ERROR", message: "Internal server error" },
                status: 500,
            },
        },
    ];

    const randomError = errors[Math.floor(Math.random() * errors.length)];
    throw randomError;
}


export async function changePasswordFake(userCode: string, oldPassword: string, newPassword: string): Promise<{
    token: string;
    expire_at: number;
    role: string;
    user_code: string;
    user_name: string;
}> {
    const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
    await delay(500);
    const errors = [
        {
            response: {
                data: { code: "INVALID_OLD_PASSWORD", message: "Old password is incorrect" },
                status: 400,
            },
        },
        {
            response: {
                data: { code: "SAME_PASSWORD", message: "New password is the same as the old one" },
                status: 400,
            },
        },
        {
            response: {
                data: { code: "WEAK_PASSWORD", message: "New password is too weak" },
                status: 400,
            },
        },
        {
            response: {
                data: { code: "FORBIDDEN_PASSWORD_CHANGE", message: "Password change not allowed" },
                status: 403,
            },
        },
        {
            response: {
                data: { code: "SERVER_ERROR", message: "Internal server error" },
                status: 500,
            },
        },
    ];

    const randomError = errors[Math.floor(Math.random() * errors.length)];
    throw randomError;

}