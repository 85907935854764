import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

interface GLTFViewerProps {
  modelPath?: string;
  loading: boolean;
  onError: () => void;
  width: number;
}

const GLTFViewer: React.FC<GLTFViewerProps> = ({ modelPath, loading, width, onError }) => {
  const mountRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!loading) {
      const scene = new THREE.Scene();
      scene.background = new THREE.Color('#f5f5f5');

      const containerWidth = width;
      const containerHeight = 800;

      const camera = new THREE.PerspectiveCamera(75, containerWidth / containerHeight, 0.1, 1000);
      const renderer = new THREE.WebGLRenderer({ antialias: true });
      renderer.setSize(containerWidth, containerHeight);

      mountRef.current?.appendChild(renderer.domElement);

      camera.position.z = 2;

      const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
      scene.add(ambientLight);

      const directionalLight = new THREE.DirectionalLight(0xffffff, 3);
      directionalLight.position.set(5, 5, 5);
      camera.position.set(5, 5, 5);
      camera.lookAt(0, 0, 0);
      scene.add(directionalLight);

      const loader = new GLTFLoader();
      let mixer: THREE.AnimationMixer | undefined;
      let model: THREE.Object3D | null = null;
      const clock = new THREE.Clock();

      if (modelPath)
        loader.load(
          modelPath,
          (gltf) => {
            model = gltf.scene;
            scene.add(model);

            if (gltf.animations && gltf.animations.length > 0) {
              mixer = new THREE.AnimationMixer(model);
              gltf.animations.forEach((clip) => {
                mixer?.clipAction(clip).play();
              });
            }
          },
          undefined,
          (error) => {
            console.error('An error occurred loading the GLTF model:', error);
            onError();
          }
        );

      const controls = new OrbitControls(camera, renderer.domElement);
      controls.enableDamping = true;

      const animate = () => {
        requestAnimationFrame(animate);
        const delta = clock.getDelta();
        if (mixer) mixer.update(delta);
        controls.update();
        renderer.render(scene, camera);
      };

      const handleResize = () => {
        const newWidth = mountRef.current?.offsetWidth || containerWidth;
        camera.aspect = newWidth / containerHeight;
        camera.updateProjectionMatrix();
        renderer.setSize(newWidth, containerHeight);
      };

      window.addEventListener('resize', handleResize);
      animate();

      // Funkce pro odstranění modelu a vyčištění paměti
      const disposeModel = () => {
        if (model) {
          scene.remove(model);
          model.traverse((child) => {
            if ((child as THREE.Mesh).isMesh) {
              const mesh = child as THREE.Mesh;
              if (mesh.geometry) mesh.geometry.dispose();
              if (Array.isArray(mesh.material)) {
                mesh.material.forEach((mat) => mat.dispose());
              } else if (mesh.material) {
                mesh.material.dispose();
              }
            }
          });
        }
        model = null;
      };

      return () => {
        disposeModel(); // Vyčistit model
        mountRef.current?.removeChild(renderer.domElement);
        renderer.dispose(); // Uvolnit renderer
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [loading, width, modelPath]);

  return (
    <>
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: `${width}px`,
            height: '800px',
            fontSize: '18px',
            color: '#555',
            backgroundColor: '#f0f0f0',
            border: '1px solid #ccc',
            borderRadius: '8px',
          }}
        >
          Probíhá načítání modelu
        </div>
      ) : (
        <div ref={mountRef} style={{ width: `${width}px`, height: '800px' }} />
      )}
    </>
  );
};

export default GLTFViewer;
