import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { t } from "../features/i18n";
import PanoramaViewer from "../features/ThreeSixtyViewer/PanoramaViewer";
import { DeleteAsset, DownloadAssetPart, ListAssets } from "../apiRequest/assets";
import CreatePanoramaModal from "../components/modals/CreatePanoramaModal";
import { VirtualRealityIcon } from "../components/icons/menu/VirtualRealityIcon";
import { useNavigate } from "react-router-dom";
import UpdatePanoramaModal from "../components/modals/UpdatePanoramaModal";
import ApproveModal from "../components/modals/ApproveModal";
import DeleteModal from "../components/modals/DeleteModal";

export interface IPanoramas { }

const Panoramas: React.FC<IPanoramas> = (props) => {
  const [images, setImages] = useState<IImageParameters[]>([]);
  const [groupedData, setGroupedData] = useState<GroupedImages>();
  const [dataCheck, setDataCheck] = useState<boolean>(false);
  const apiCalled = useRef(false);
  const [openCreatePanoramaModal, setOpenCreatePanoramaModal] = useState(false);
  const [openUpdatePanoramaModal, setOpenUpdatePanoramaModal] = useState(false);
  const [editPanoramaData, setEditPanoramaData] = useState<IUpdateAssetData>();
  const [deletePanoramaData, setDeletePanoramaData] = useState<IUpdateAssetData>();
  const [approveDeletePanoramaModal, setApproveDeletePanoramaModal] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  // Funkce pro aktualizaci šířky
  const updateWidth = () => {
    const newWidth = window.innerWidth;
    setWidth(newWidth + 240);
  };

  // Použití useEffect pro naslouchání změnám velikosti okna
  useEffect(() => {
    window.addEventListener('resize', updateWidth);

    // Nastavení šířky při prvním renderu
    updateWidth();

    // Cleanup při unmountu
    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, []);

  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleResetPage = () => {
    fetchImages()
  };

  const handleDeletePanorama = (data: IImageParameters[]) => {

    if (data[0].code && data[0].description && data[0].name) {
      setDeletePanoramaData({
        code: data[0].code,
        description: data[0].description,
        name: data[0].name,
      });
      setApproveDeletePanoramaModal(true);
    }

  };

  const handleEditPanorama = (data: IImageParameters[]) => {
    if (data[0].code && data[0].description && data[0].name) {
      setEditPanoramaData({
        code: data[0].code,
        description: data[0].description,
        name: data[0].name,
      });
      setOpenUpdatePanoramaModal(true);
    }
  };

  async function loadData(): Promise<void> {
    if (dataCheck !== true) {
      setDataCheck(false);
      fetchImages();
      console.log(images);
    }
  }

  useEffect(() => {
    if (!apiCalled.current) {
      loadData();
      apiCalled.current = true;
    }
  }, []);

  const fetchImages = async () => {
    try {

      // Step 1: Fetch the local image data from the JSON file
      const response = await fetch("/Panorama/PanoramaGalleryData.json");
      const localData = await response.json();

      // Step 2: Fetch the assets from the API
      const apiAssets = await ListAssets("panorama"); // Specify asset_type if necessary
      console.log("apiAssets:");
      console.log(apiAssets);
      // Step 3: Combine the local data with the assets fetched from the API
      const combinedData = combineLocalAndApiData(localData, apiAssets);
      console.log("combinedData:");
      console.log(combinedData);
      // Step 4: Add the combined data to the image handler
      await addImageData(combinedData);
    } catch (error) {
      console.error("Something went wrong while fetching images", error);
    }
  };

  const combineLocalAndApiData = (
    localData: any,
    apiAssets: IAsset[]
  ): any[] => {
    // Combine both sources of image data, either by merging or concatenating
    const combinedData = [...localData];

    apiAssets.forEach((asset) => {
      if (asset.parts > 0) {
        for (let part = 1; part <= asset.parts; part++) {
          combinedData.push({
            code: asset.code,
            part: part - 1,
            path: "", // Will be filled in later by the API download
            fileName: `${asset.name}_part${part - 1}.${asset.extension}`,
            description: asset.description,
            amount: 1,
            name: asset.name,
          });
        }
      }
    });

    return combinedData;
  };

  const groupAndSetIdByAssetCode = (
    items: IImageParameters[]
  ): IImageParameters[] => {
    const groupedItems: Record<string, IImageParameters[]> = {};
    const unchangedItems: IImageParameters[] = [];
    items.forEach((item) => {
      if (item.code) {
        if (!groupedItems[item.code]) {
          groupedItems[item.code] = [];
        }
        groupedItems[item.code].push(item);
      } else {
        unchangedItems.push(item);
      }
    });

    const result: IImageParameters[] = [];
    Object.keys(groupedItems).forEach((code) => {
      const group = groupedItems[code];
      const minId = Math.min(...group.map((item) => item.id));
      const totalAmount = group.length;

      group.forEach((item) => {
        result.push({
          ...item,
          id: minId,
          amount: totalAmount,
        });
      });
    });

    return [...result, ...unchangedItems];
  };

  const addImageData = async (data: any) => {
    let imagesData: IImageParameters[] = [];
    await Promise.all(
      data.map(async (img: any, index: any) => {
        if (
          img.amount !== undefined &&
          img.path !== undefined &&
          img.fileName !== undefined
        ) {
          for (let i = 1; i <= img.amount; i++) {
            const filepath = img.path.replace("{index}", i.toString());
            const fileName = img.fileName.replace("{index}", i.toString());
            const fullpath = filepath + "/" + fileName;

            let description = t("missing_description");
            if (img.description !== undefined) {
              description = img.description;
            }

            const image: IImageParameters = {
              id: index,
              amount: img.amount,
              path: fullpath,
              fileName: fileName,
              firstImagePath: fullpath,
              rotation: "",
              description: description,
            };

            // Check if the image needs to be downloaded from the API
            if (img.code && img.part !== undefined) {
              try {
                // Download image part from the API
                const imageBlob = await DownloadAssetPart(img.code, img.part);

                // Create an object URL for the downloaded blob
                const objectURL = URL.createObjectURL(imageBlob);

                // Replace the fullpath with the downloaded image's object URL
                image.code = img.code;
                image.firstImagePath = objectURL;
                image.path = objectURL;
                image.name = img.name;
              } catch (error) {
                console.error(
                  `Error downloading asset part: ${img.asset_code}, part: ${img.part}`,
                  error
                );
              }
            }

            imagesData.push(image);
          }
        }

        if (imagesData.length !== 0) {
          // Set images in state
          imagesData = groupAndSetIdByAssetCode(imagesData);
          setImages(groupAndSetIdByAssetCode(imagesData));

          // Group the images by 'id'
          const groupedData: GroupedImages = imagesData.reduce(
            (acc: GroupedImages, item: IImageParameters) => {
              if (!acc[item.id]) {
                acc[item.id] = [];
              }
              acc[item.id].push(item);
              return acc;
            },
            {}
          );

          setGroupedData(groupedData);
          console.log(`Panoramas 123 groupedData ${groupedData} `);
          console.log(groupedData);
          setDataCheck(true);
        }
      })
    );
  };

  return (
    <div>
      <Box display="flex" alignItems="center" sx={{ mb: "45px " }}>
        <Typography variant="h4" sx={{ color: "#9b4c97", mb: 5 }}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item sx={{ mr: "36px" }}>
              <VirtualRealityIcon />
            </Grid>
            <Grid item>{t("panoramas")}</Grid>
          </Grid>
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            position: "absolute",
            right: 16,
          }}
        >
          <Button
            variant="outlined"
            onClick={handleGoBack}
            sx={{
              width: 184,
              height: 40,
              color: "#72b855",
              textTransform: "none",
              fontWeight: "bold",
              borderColor: "#72b855",
              marginBottom: 2,
            }}
          >
            {t("back")}
          </Button>
          <Button
            variant="outlined"
            onClick={() => setOpenCreatePanoramaModal(true)}
            sx={{
              width: 184,
              height: 40,
              color: "#9b4c97",
              textTransform: "none",
              fontWeight: "bold",
              borderColor: "#9b4c97",
            }}
          >
            {t("panoramas_modal_open")}
          </Button>
        </Box>
      </Box>

      <CreatePanoramaModal
        open={openCreatePanoramaModal}
        onClose={() => setOpenCreatePanoramaModal(false)}
        resetTable={handleResetPage}
      />

      {editPanoramaData ? (
        <UpdatePanoramaModal
          open={openUpdatePanoramaModal}
          onClose={() => setOpenUpdatePanoramaModal(false)}
          resetTable={handleResetPage}
          panoramaData={editPanoramaData}
        />
      ) : (
        <></>
      )}

      {dataCheck && groupedData ? (
        <PanoramaViewer
          images={groupedData}
          onDelete={handleDeletePanorama}
          onEdit={handleEditPanorama}
          width={width}
        />
      ) : (
        <Typography sx={{}}>{t("not_find_any_models")}</Typography>
      )}

      <DeleteModal
        deleteMessage={t("panoramas_modal_asset_delete") + deletePanoramaData?.name}
        errorMessage={t("panoramata se nepovedlo vymazat")}
        successMessage={t("panoramata byly uspesne vymazany")}
        open={approveDeletePanoramaModal}
        type={"assets"}
        code={deletePanoramaData?.code}
        reset={handleResetPage}
        onClose={() => setApproveDeletePanoramaModal(false)}
      />
    </div>
  );
};

export default Panoramas;
