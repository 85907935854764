import React, { useEffect, useState } from 'react';
import { Modal, Box, Button, Theme, Paper, Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";
import { t } from '../../features/i18n';
import MessageComponent from '../MessageComponent';
import { DeleteAsset } from '../../apiRequest/assets';
import { DeleteRemark } from '../../apiRequest/remark';
const color = "red"
const useStyles = makeStyles<Theme>((theme) => ({
    paper: {
        borderTop: `8px solid red`,
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 500,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: "40px",
    },
    tableRow: {
        "&:not(:last-child)": {
            borderBottom: "none",
        },
    },
}));

type SupportedTypes = 'assets' | 'product' | 'order' | 'remark';

interface IDeleteModal {
    deleteMessage: string;
    successMessage?: string;
    errorMessage?: string;
    code?: string;
    open: boolean;
    type: SupportedTypes;
    reset: () => void;
    onClose: () => void;
}

const DeleteModal: React.FC<IDeleteModal> = ({ deleteMessage, successMessage, errorMessage, code, open, type, reset, onClose }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [successAlertOpen, setSuccessAlertOpen] = useState(false);
    const [errorAlertOpen, setErrorAlertOpen] = useState(false);
    const classes = useStyles();
    useEffect(() => {
        if (open) {
            setLoading(false);
            setSuccessAlertOpen(false);
            setErrorAlertOpen(false);
        }
    }, [open]);

    const handleSubmit = async () => {
        try {
            setLoading(true)
            await handleDeleteType()
            setTimeout(() => {
                setSuccessAlertOpen(true);
                setTimeout(() => {
                    reset();
                    onClose();
                }, 2000);
            }, 2000);
        } catch (error) {
            console.error('Chyba při odstranovani:', error);
            setSuccessAlertOpen(true);
            setErrorAlertOpen(true);
            setLoading(false);
            setTimeout(() => {
                setSuccessAlertOpen(false);
                setErrorAlertOpen(false);
                onClose();
            }, 2000);
        }
    };

    async function handleDeleteType() {
        switch (type) {
            case 'assets':
                if (code)
                    await DeleteAsset(code);
                break;

            case 'remark':
                if (code)
                    await DeleteRemark(Number(code));
                break;
            default:
                errorMessage = "any request for this type delete"
                throw errorMessage
        }
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Paper className={classes.paper}>
                {successAlertOpen ? (
                    <MessageComponent
                        errorChange={errorAlertOpen}
                        errorMessage={errorMessage ?? "error while delete"}
                        successMessage={successMessage ?? "delete was succesfull"}
                    />
                ) : (
                    <Box>
                        <Typography
                            sx={{
                                fontSize: "24px",
                            }}
                        >
                            {deleteMessage}
                        </Typography>
                        <Button
                            variant="outlined"
                            onClick={onClose}
                            sx={{
                                width: 140,
                                height: 40,
                                color: color,
                                textTransform: "none",
                                fontWeight: "bold",
                                borderColor: color,
                            }}
                        >
                            {t("cancel")}
                        </Button>
                        <Button
                            variant="contained"
                            type="submit"
                            disabled={loading}
                            onClick={handleSubmit}
                            sx={{
                                width: 140,
                                height: 40,
                                bgcolor: color,
                                "&:hover": {
                                    backgroundColor: color,
                                },
                                fontWeight: "bold",
                                position: "absolute",
                                right: "40px",
                            }}
                        >
                            {loading ? t("processing") : t("confirm")}
                        </Button>

                    </Box>
                )}
            </Paper>
        </Modal>
    );
};

export default DeleteModal;
