import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Paper,
  Theme,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useForm, SubmitHandler } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { t } from "../../features/i18n";
import { RootState } from "../../features/redux/store";
import { useDispatch, useSelector } from "react-redux";
import MessageComponent from "../MessageComponent";
import { changePassword } from "../../apiRequest/auth";
import { dispatchLogin, parseToken } from "../../features/authentication/helpers/token.helper";
import { useSessionExpiration } from "../../features/authentication/SessionExpiration.hook";
import { Visibility, VisibilityOff } from "@mui/icons-material";

let color: string = "#302c2c";

const schema = z.object({
  oldpassword: z.string()
    .min(1, t("password_is_required")),
  new_password: z.string()
    .min(1, t("password_is_required"))
    .min(8, t("password_min_8_letters"))
    .max(32, t("password_max_32_letters")),
  passwordConfirm: z.string().min(1, t("please_confirm_password")),
})
  .refine((data) => data.new_password === data.passwordConfirm, {
    path: ["passwordConfirm"],
    message: t("passwords_not_same"),
  })
  .refine((data) => data.oldpassword !== data.new_password, {
    path: ["new_password"],
    message: t("passwords_old_new_same"),
  });

type FormData = z.infer<typeof schema>;

const useStyles = makeStyles<Theme>((theme) => ({
  paper: {
    borderTop: `8px solid ${color}`,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: "40px",
  },
  tableRow: {
    "&:not(:last-child)": {
      borderBottom: "none",
    },
  },
}));

type IChangePasswordModal = {
  open: boolean;
  onClose: () => void;
};

const ChangePasswordModal: React.FC<IChangePasswordModal> = ({ open, onClose }) => {
  const userCode = useSelector((state: RootState) => state.user?.userCode);
  const [loading, setLoading] = useState(false);
  const [successAlertOpen, setSuccessAlertOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  const classes = useStyles();
  const { activateSessionExpiration } = useSessionExpiration();
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleClickShowOldPassword = () => setShowOldPassword((prev) => !prev);
  const handleClickShowNewPassword = () => setShowNewPassword((prev) => !prev);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword((prev) => !prev);

  const {
    reset,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormData>({
    resolver: zodResolver(schema),
  });

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    try {
      const validatedData = schema.parse(data);
      setLoading(true);

      if (userCode) {
        const response = await changePassword(userCode, validatedData.oldpassword, validatedData.new_password);
        const { token } = response;

        const { isValid, tokenContent } = parseToken(token);
        if (isValid && tokenContent) {
          dispatchLogin(dispatch, tokenContent);
          localStorage.setItem("token", token);
          activateSessionExpiration(true);
          setErrorMessage("");
        } else {
          setErrorMessage(t("invalid_token"));
        }
      }

      setSuccessAlertOpen(true);
      setTimeout(onClose, 2000);
    } catch (error) {
      console.error("Error during password change:", error);
      setErrorMessage(handleError(error));
      console.error("errorMessage", errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const handleError = (error: any): string => {
    console.error("handleError", error);
    if (error?.response?.data?.code) {
      switch (error.response.data.code) {
        case "INVALID_OLD_PASSWORD":
          return t("invalid_old_password");
        case "SAME_PASSWORD":
          return t("same_password");
        case "WEAK_PASSWORD":
          return t("weak_password");
        case "FORBIDDEN_PASSWORD_CHANGE":
          return t("forbidden_password_change");
        default:
          return t("change_password_failed");
      }
    } else if (error?.response?.status) {
      // HTTP status kódy jako fallback
      switch (error.response.status) {
        case 401:
          return t("unauthorized");
        case 500:
          return t("server_error");
        default:
          return t("unknown_error_occurred");
      }
    } else {
      console.error("Unhandled error:", error);
      return t("unknown_error_occurred");
    }
  };

  useEffect(() => {
    if (open) {
      setLoading(false);
      setSuccessAlertOpen(false);
      setErrorMessage("");
      reset();
    }
  }, [open]);

  const handleCancel = () => {
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Paper className={classes.paper}>
        {successAlertOpen ? (
          <MessageComponent
            errorChange={false}
            errorMessage={t("password_change_error")}
            successMessage={t("password_changed_successfully")}
          />
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              sx={{ mb: 2, mt: 2 }}
              label={t("old_password")}
              type={showOldPassword ? "text" : "password"}
              fullWidth
              {...register("oldpassword")}
              error={!!errors.oldpassword}
              helperText={errors.oldpassword?.message}
              InputProps={{
                endAdornment: (
                  <Box
                    onClick={handleClickShowOldPassword}
                    sx={{ cursor: "pointer", color: "#000" }}
                  >
                    {!showOldPassword ? <VisibilityOff /> : <Visibility />}
                  </Box>
                ),
              }}
            />
            <TextField
              sx={{ mb: 2 }}
              label={t("new_password")}
              type={showNewPassword ? "text" : "password"}
              fullWidth
              {...register("new_password")}
              error={!!errors.new_password}
              helperText={errors.new_password?.message}
              InputProps={{
                endAdornment: (
                  <Box
                    onClick={handleClickShowNewPassword}
                    sx={{ cursor: "pointer", color: "#000" }}
                  >
                    {!showNewPassword ? <VisibilityOff /> : <Visibility />}
                  </Box>
                ),
              }}
            />
            <TextField
              sx={{ mb: 2 }}
              label={t("confirm_new_password")}
              type={showConfirmPassword ? "text" : "password"}
              fullWidth
              {...register("passwordConfirm")}
              error={!!errors.passwordConfirm}
              helperText={errors.passwordConfirm?.message}
              InputProps={{
                endAdornment: (
                  <Box
                    onClick={handleClickShowConfirmPassword}
                    sx={{ cursor: "pointer", color: "#000" }}
                  >
                    {!showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </Box>
                ),
              }}
            />
            {errorMessage && (
              <Typography sx={{ color: "red", mb: 2 }}>
                {errorMessage}
              </Typography>
            )}
            <Button
              variant="outlined"
              onClick={handleCancel}
              sx={{
                width: 140,
                height: 40,
                color: color,
                textTransform: "none",
                fontWeight: "bold",
                borderColor: color,
              }}
            >
              {t("cancel")}
            </Button>

            <Button
              variant="contained"
              type="submit"
              disabled={loading}
              sx={{
                width: 140,
                height: 40,
                bgcolor: color,
                "&:hover": {
                  backgroundColor: color,
                },
                fontWeight: "bold",
                position: "absolute",
                right: "40px",
              }}
            >
              {loading ? t("processing") : t("confirm")}
            </Button>
          </form>
        )}
      </Paper>
    </Modal>
  );
};

export default ChangePasswordModal;
